import { cn } from '@finn/ui-utils';
import { RefObject, useEffect } from 'react';
import handleViewport from 'react-in-viewport';

import { addNewProductListView } from '../tracking';
import { AttributesRow } from './components/AttributesRow';
import { AttributesRowExp } from './components/AttributesRowExp';
import { Consumption } from './components/Consumption';
import { Label } from './components/Label';
import { ProductImage } from './components/ProductImage';
import { ProductLink } from './components/ProductLink';
import { ProductPrice } from './components/ProductPrice';
import { useProductLabel } from './hooks/useProductLabel';
import { GenericVehicleDetails } from './types';

type Props = {
  vehicle: GenericVehicleDetails;
  parentTitle?: string;
  enterCount?: number;
  leaveCount?: number;
  shouldPreload?: boolean;
  shouldShowPlaceholder?: boolean;
  forwardedRef?: RefObject<HTMLDivElement>;
  position?: number;
  baseURL?: string;
  urlParams?: string;
  isForIframe?: boolean;
  hidePrice?: boolean;
  showCompare?: boolean;
  forceB2BPrice?: boolean;
  lastVisited?: boolean;
  selectedTerm?: number;
  partnerDiscount?: number;
  target?: 'listing' | 'details';
  priceType?: 'cents';
  forceRegularPrice?: boolean;
  showComparisonInfo?: boolean;
};

const ProductCard = ({
  vehicle,
  parentTitle,
  enterCount,
  leaveCount,
  shouldPreload,
  shouldShowPlaceholder = true,
  forwardedRef,
  position,
  baseURL = '',
  urlParams,
  isForIframe = false,
  hidePrice = false,
  forceB2BPrice = false,
  lastVisited = false,
  selectedTerm,
  target,
  partnerDiscount = 0,
  showComparisonInfo = false,
}: Props) => {
  const shouldShowPrice = !hidePrice;
  const productLabel = useProductLabel(vehicle, partnerDiscount);
  const impressionTitle = parentTitle || 'PLP - Filter Results';

  useEffect(() => {
    const isAppearFirstTimeInViewport = enterCount === 1 && leaveCount === 0;
    if (isAppearFirstTimeInViewport) {
      addNewProductListView({
        vehicle,
        list: impressionTitle,
        partnerDiscount,
      });
    }
  }, [enterCount, impressionTitle, leaveCount, vehicle]);

  const brandModelLabel = `${vehicle.brand?.id} ${vehicle.model}`;

  const vehiclePictureURL = vehicle.picture?.url || vehicle.picture?.[0]?.url;
  const vehicleAltText = `${vehicle.brand?.id} ${vehicle.model}`;

  urlParams = urlParams || (selectedTerm && `?term=${selectedTerm}`) || '';

  return (
    <ProductLink
      vehicle={vehicle}
      baseURL={baseURL}
      urlParams={urlParams}
      isForIframe={isForIframe}
      position={position}
      target={target}
    >
      <div
        className="relative flex h-full w-full flex-col overflow-hidden hover:cursor-pointer"
        ref={forwardedRef}
      >
        <div className="bg-snow relative rounded">
          <ProductImage
            vehiclePictureURL={vehiclePictureURL}
            shouldPreload={shouldPreload}
            shouldShowPlaceholder={shouldShowPlaceholder}
            altText={vehicleAltText}
          />
        </div>

        <div className="mt-2 grid gap-1">
          {(productLabel || lastVisited) && (
            <div className="py-1">
              <Label highlight={lastVisited}>
                {(lastVisited ? 'Kürzlich angesehen' : '') +
                  (productLabel && lastVisited ? ' - ' : '') +
                  (productLabel || '')}
              </Label>
            </div>
          )}
          <h3
            className={cn(
              'body-14-semibold sm:body-16-semibold overflow-hidden text-ellipsis py-1 sm:text-nowrap'
            )}
          >
            {brandModelLabel}
          </h3>
          {showComparisonInfo ? (
            <AttributesRowExp vehicle={vehicle} />
          ) : (
            <AttributesRow vehicle={vehicle} />
          )}
          {shouldShowPrice && (
            <ProductPrice
              smallerDesktopFont={showComparisonInfo}
              forceB2BPrice={forceB2BPrice}
              vehicle={vehicle}
              selectedTerm={selectedTerm}
              partnerDiscount={partnerDiscount}
              showComparisonInfo={showComparisonInfo}
            />
          )}
          {showComparisonInfo && <Consumption vehicle={vehicle} />}
        </div>
      </div>
    </ProductLink>
  );
};

export default handleViewport<any, any>(
  ProductCard,
  { threshold: 0.8 },
  { disconnectOnLeave: true }
);
